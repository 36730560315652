export const autoSuggestHomeWidget = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('POST', requestOptions)
    return fetch('api/autoSuggest',requestOptions).then((response)=>response.json())
}
export const SearchHotelHomeWidget = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('POST', requestOptions)
    return fetch('/shared/api/hotelAutoSuggest',requestOptions).then((response)=>response.json())
}

export const SearchActivityHomeWidget = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('POST', requestOptions)
    return fetch('api/activityAutoSuggest',requestOptions).then((response)=>response.json())
}
export const RecentSearchHotelWidget = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    console.log('POST', requestOptions)
    return fetch('/shared/api/hotelRecentSearch',requestOptions).then((response)=>response.json())
}


export const SetRecentSearchFlightlWidget = (req)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/api/setflightRecentSearch' ,requestOptions).then((response)=>response.json())
}

export const getRecentSearchFlightlWidget = (req)=>{
    console.log("reqqqqq",req)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'   },
        body: JSON.stringify(req)
    };
    console.log('POvvST', requestOptions)
    return fetch('/api/getflightRecentSearch' ,requestOptions).then((response)=>response.json())
}

