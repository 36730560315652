import React, { Component, createRef } from 'react';
import {
    addDays,
    differenceInCalendarDays,
    differenceInMonths,
    endOfMonth,
    format,
    getMonth,
    getYear,
    isAfter,
    isBefore,
    isFriday,
    isMonday,
    isSameDay,
    isSaturday,
    isThursday,
    isTuesday,
    isWednesday,
    startOfMonth,
    startOfToday,
    subDays
} from 'date-fns';

import cf from '../../../../lib/utils/common';
import moment from 'moment';

// import styles from "./calendar.module.css";

const weekList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default class DesktopCalender extends Component {
    constructor(props) {
        super(props);
        this.calendarWrapper = createRef();
        this.DateCellWrapper = createRef();
        this.state = {
            dateFormat: !cf.isValueEmpty(this.props.dateFormat) ? this.props.dateFormat : "",
            numberOfClicks: 0,
            twoCalendarsArray: [],
            isDoubleSelection: !cf.isValueEmpty(this.props.doubleSelection) ? this.props.doubleSelection : false,
            dateLimitArr: this.props.startDateLimit,
            currentMonths: [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: startOfMonth(new Date()),
            selectedMonthEndDate: endOfMonth(startOfMonth(new Date())),
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            hoverEndDate: this.props.endDate,
            totalDays: "",
            pageX: "",
            pageY: "",
            initPageX: "",
            initPageY: "",
        };
    }
    componentDidMount() {
        console.log("🚀 ~ file: DesktopCalender.js:56 ~ DesktopCalender ~ componentDidMount ~ componentDidMount:", this.props)
        
        this.setState({
            currentMonths: this.props.type === "double" ? !cf.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))),
                getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
                [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !cf.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
                [getMonth(startOfMonth(new Date()))],
             selectedMonthStartDate: !cf.isValueEmpty(this.state.startDate) ? startOfMonth(new Date(this.specDateEle(this.state.startDate, 'y'), this.specDateEle(this.state.startDate, 'm'), this.specDateEle(this.state.startDate, 'd'))) : startOfMonth(startOfToday()),
        })
        document.addEventListener("mousedown", this.handleClickOutside, false);
        document.addEventListener("keydown", this.handleTabOutside, false);
        window.addEventListener("mousemove", this.logMousePosition, false);
    }
    componentDidUpdate() {
        if (this.props.endDate !== this.state.endDate) {
            this.setState({ endDate: this.props.endDate });
        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
        document.removeEventListener('keydown', this.handleTabOutside, false);
        document.removeEventListener('mousemove', this.logMousePosition, false);
    }
    handleTabOutside = (e) => {
        if (e.key !== "Tab") {
            return
        }
        if (this.calendarWrapper.current && !this.calendarWrapper.current.contains(event.target)) {
            this.props.changeVisibility(false)
        }
    }
    handleClickOutside = event => {
        if (this.calendarWrapper.current && !this.calendarWrapper.current.contains(event.target)) {
            this.props.changeVisibility(false)
        }
    };
    logMousePosition = event => {
        if (this.props.isVisible) {
            if (this.calendarWrapper.current && !this.calendarWrapper.current.contains(event.target)) {

            }
        }
    }
    componentWillReceiveProps(nextProps) {    
        if (nextProps.startDateLimit ) {
            this.setState({
                dateLimitArr:nextProps.startDateLimit,
                startDate:nextProps.startDate
            },()=>{
                this.setState({
                    currentMonths: this.props.type === "double" ?
                     !cf.isValueEmpty(this.state.dateLimitArr) ?
                        [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))),getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
                        [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !cf.isValueEmpty(this.state.dateLimitArr) ?
                        [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
                        [getMonth(startOfMonth(new Date()))],
                        // selectedMonthStartDate: !cf.isValueEmpty(this.state.startDate) ? startOfMonth(new Date(this.specDateEle(this.state.startDate, 'y'), this.specDateEle(this.state.startDate, 'm'), this.specDateEle(this.state.startDate, 'd'))) : startOfMonth(startOfToday()),
                    })
            })
        }
    }
    specDateEle(date, type) {
        try {
            let getDate = cf.isValueEmpty(this.state.dateFormat) ? moment(new Date(date)).format('DD/MMM/yyyy') : moment(date).format(this.state.dateFormat);
            let getYear = typeof date === 'string' ? new Date(getDate).getFullYear() : date.getFullYear();
            let getMonth = typeof date === 'string' ? new Date(getDate).getMonth() : date.getMonth();
            let getDay = typeof date === 'string' ? new Date(getDate).getDate() : date.getDate();
            switch (type) {
                case 'y':
                    return getYear
                case 'm':
                    return getMonth
                case 'd':
                    return getDay
                case 'a':
                    return [getYear, getMonth, getDay];
                default:
            }
        } catch (err) {
            console.log('catch yyyyy', err)
        }
    }
    onClickChangeVisibility = () => {
        this.props.changeVisibility(/* !this.props.isVisible */true)
        this.setState({
            selectedMonthStartDate : startOfMonth( new Date(this.props.value)),
            selectedMonthEndDate: endOfMonth(new Date(this.props.value)),
        }) 
    }


    onClickPrevNextArrow = (type) => {
        console.log("🚀 ~ file: DesktopCalender.js:144 ~ DesktopCalender ~ type:", type)
        
        let startMonth = subDays(this.state.selectedMonthStartDate, 1);
        let endMonth = addDays(this.state.selectedMonthEndDate, 1);
        if (this.props.type === "double") {
            if (type == "prev") {
                this.state.currentMonths[1] = getMonth(startMonth) - 1;
            } else {
                this.state.currentMonths[1] = getMonth(endMonth) + 1;
            }
        }
        if (type == "prev") {
            this.state.currentMonths[0] = getMonth(this.state.selectedMonthStartDate) - 1;
            
            this.setState({
                currentMonths: [...this.state.currentMonths],
                selectedMonthStartDate: startOfMonth(startMonth),
                selectedMonthEndDate: endOfMonth(startMonth)
            },()=>{
            })
        } else {

            this.state.currentMonths[0] = getMonth(this.state.selectedMonthStartDate) - 1;
            this.setState({
                currentMonths: [...this.state.currentMonths],
                selectedMonthStartDate: startOfMonth(endMonth),
                selectedMonthEndDate: endOfMonth(endMonth)
            },()=>{
            })
        }
      
    }

    onClickDateCell = (day, month, year, bool) => {
        if (!bool) { //new added
            if (this.state.isDoubleSelection === true) {
                let date = new Date(year, month, day);
                if (this.state.numberOfClicks === 0) {
                    this.setState({ numberOfClicks: 1, totalDays: '' }, () => {
                        this.props.onChangeDate(date, 'startDate');
                        this.props.onChangeDate('', 'endDate');
                    });
                }
                if (this.state.numberOfClicks === 1) {
                    let differenceInDays = differenceInCalendarDays(new Date(date), new Date(this.props.startDate))
                    this.setState({ numberOfClicks: 0, totalDays: '' }, () => {
                        if (differenceInDays < 0) {
                            this.props.onChangeDate(date, 'startDate');
                            this.props.onChangeDate('', 'endDate');
                        } else {
                            this.setState({ numberOfClicks: 0 }, () => {
                                this.props.onChangeDate(date, 'endDate');
                                // this.props.changeVisibility(!this.props.isVisible)
                            });
                        }
                    });
                }
            } else {
                if (bool === false) {
                    let date = new Date(year, month, day);
                    this.props.onChangeDate(date);
                    // this.props.changeVisibility(!this.props.isVisible)
                }
            }
        }
    }
    renderCalendar() {
        let temp = this.state.selectedMonthStartDate;
        let twoCalendarsArray = [];
        for (let i = 0; i < this.state.currentMonths.length; i++) {
            let StartDateOfMonth = temp;
            let monthHead = addDays(StartDateOfMonth, i);
            let endDateOfMonth = endOfMonth(monthHead);
            let dayDifference = differenceInCalendarDays(endDateOfMonth, monthHead);
            let days = [];
            let month = getMonth(monthHead)
            let year = getYear(monthHead)
            let startOfMonthDay = isMonday(monthHead) ? 0 : isTuesday(monthHead) ? 1 : isWednesday(monthHead) ? 2 : isThursday(monthHead) ? 3 : isFriday(monthHead) ? 4 : isSaturday(monthHead) ? 5 : 6;
            let startDateLimit = this.props.startDateLimit;            
            let isHoliday = this.props.isHoliday ? this.props.isHoliday: false;
            let holidayStartDate = this.props.holidayStartDate ? this.props.holidayStartDate : '';
            let holidayEndDate = this.props.holidayEndDate ? this.props.holidayEndDate : '';
            let startDate = this.props.startDate;
            let endDate = this.state.endDate;
            let hoverDate = this.state.hoverEndDate;
            for (let k = 0; k < startOfMonthDay; k++) {
                days = [...days, { day: "", month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
            }
            for (let j = 0; j <= dayDifference; j++) {
                let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(startDateLimit, 'y'), this.specDateEle(startDateLimit, 'm'), this.specDateEle(startDateLimit, 'd')));
                let disableDate = false;
                if(isHoliday){
                    let isHLDBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(holidayStartDate, 'y'), this.specDateEle(holidayStartDate, 'm'), this.specDateEle(holidayStartDate, 'd')));
                    let isHLDAfterDate = isAfter(new Date(year, month, j + 1), new Date(this.specDateEle(holidayEndDate, 'y'), this.specDateEle(holidayEndDate, 'm'), this.specDateEle(holidayEndDate, 'd')));
                    disableDate = isHLDBeforeDate == true || isHLDAfterDate == true ? true: false;
                }
                if (isBeforeDate === true || disableDate == true) {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
                } else {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: false, selectedDate: false, highlight: false }];
                }
            }
            if (this.state.isDoubleSelection === true) {
                let startDateIndex = days.findIndex((items) => !cf.isValueEmpty(items.day) && new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')).toString());
                let endDateIndex = days.findIndex((items) => !cf.isValueEmpty(items.day) && new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')).toString());
                if (startDateIndex !== -1) {
                    days[startDateIndex].selectedDate = true;
                }
                if (endDateIndex !== -1) {
                    days[endDateIndex].selectedDate = true;
                }
            } else {
                let SelectedDate = this.props.value;
                let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(SelectedDate, 'y'), this.specDateEle(SelectedDate, 'm'), this.specDateEle(SelectedDate, 'd')).toString())
                if (selectedDateIndex !== -1) {
                    days[selectedDateIndex].selectedDate = true;
                }
            }
            temp = endDateOfMonth;
            twoCalendarsArray.push(
                <div className={this.props.styles.calendar_whole_body}>
                    <div className={this.props.styles.calendar_head}>
                        <div className={this.props.styles.calendar_head_center_side}>
                            <h3>{format(monthHead, 'MMMM yyyy')}</h3>
                        </div>
                    </div>
                    <div className={this.props.styles.calendar_body}>
                        <div className={this.props.styles.calendar_week_heads}>
                            {weekList.map((ele, idx) => {
                                return (
                                    <div className={this.props.styles.coln_center_week} key={"weekl" + idx}>
                                        {ele}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={this.props.styles.calendar_day_list}>
                            {days.map((ele, idx) => {
                                let isHighLight = false;
                                let isHoverDate = false;
                                if (this.state.isDoubleSelection === true) {
                                    let isBeforeDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(hoverDate, 'y'), this.specDateEle(hoverDate, 'm'), this.specDateEle(hoverDate, 'd')));
                                    let isBeforeEndDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')));
                                    let isAfterDate = isAfter(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')));
                                    isHoverDate = isSameDay(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(hoverDate, 'y'), this.specDateEle(hoverDate, 'm'), this.specDateEle(hoverDate, 'd')));
                                    if ((isAfterDate && isBeforeDate && cf.isValueEmpty(endDate)) || (isAfterDate && isBeforeEndDate && (!cf.isValueEmpty(endDate)))) {
                                        isHighLight = true;
                                    }
                                }
                                return (
                                    <div className={(ele.selectedDate === true && ele.day) ? this.props.styles.day_cell_center + ' ' + this.props.styles.selected_calendar_date : isHighLight === true && ele.day != "" ? this.props.styles.day_cell_center_highlight : this.props.styles.day_cell_center} key={i}
                                        onClick={() => this.onClickDateCell(ele.day, ele.month, ele.year, ele.isDisable)} onMouseEnter={(e) => this.onHoverDayCell(ele, e)} >
                                        {this.state.isDoubleSelection === true && !cf.isValueEmpty(ele.day) && ele.isDisable === false && !cf.isValueEmpty(hoverDate) && isHoverDate && !cf.isValueEmpty(this.state.totalDays) && <div class={this.props.styles.container_tooltip}>{this.state.totalDays} Night</div>}
                                        <span className={ele.isDisable === true ? this.props.styles.calendar_day_disable : ''}>{ele.day}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        return twoCalendarsArray
    }
    onHoverDayCell = (currentDate, e) => {
        let date = moment(new Date(currentDate.year, currentDate.month, currentDate.day)).format(this.props.dateFormat ? this.props.dateFormat : "DD/MMM/yyyy");
        this.setState({
            hoverEndDate: date
        })
        if (!cf.isValueEmpty(this.props.startDate) && cf.isValueEmpty(this.state.endDate)) {
            this.setState({
                hoverEndDate: date, pageX: Number(e.clientX) - Number(this.state.initPageX), pageY: Number(e.clientY) - Number(this.state.initPageY)
            }, () => {
                let difference = differenceInCalendarDays(new Date(currentDate.year, currentDate.month, currentDate.day), new Date(this.props.startDate))
                if (difference > 0) this.setState({ totalDays: difference })
                else this.setState({ totalDays: '' })
            })
        }
    }
    onMouseMoveCalendar = (e, eventUsed) => {
        switch (eventUsed) {
            case 'onMouseEnter':
                this.setState({ initPageX: e.clientX, initPageY: e.clientY })
                break;
            default:
                break;
        }
        e.preventDefault()
    }
    render() {
        return (
            <div className={this.props.styles.calendar_wid_container}>
                <label>{this.props.label}</label>
                <div className={this.props.styles.calendar_wid_main}
                          onClick={() => {
                            if (this.props.triptype == "oneWay" && this.props.label == "Return")
                              this.props.selectTripbtn();
                          }}
                >
                    {!cf.isValueEmpty(this.props.iconImage) ? (
                        // <img src={this.props.iconImage} className={this.props.styles.flight_search_wid_inputfield_img} />
                        <span className={this.props.styles.flight_search_wid_inputfield_img} onClick={() => this.onClickChangeVisibility()}>
                            {this.props.iconImage}
                        </span>
                    ) : null}
                    {this.state.isDoubleSelection === true ? (
                        <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
                            placeholder={!cf.isValueEmpty(this.props.placholder) ? this.props.placholder : ""}
                            value={!cf.isValueEmpty(this.props.value) ? this.props.value : ""}
                            onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}
                            onFocus={(e) => { /* typeof this.props.onInputFocus === "function" ? this.props.onInputFocus() : () => { } */e.stopPropagation(); this.onClickChangeVisibility() }}
                            tabindex="2"
                            style={{caretColor: 'transparent', cursor: 'pointer'}}
                        />
                    ) : (
                        <>
                        {/* <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
                            placeholder={!cf.isValueEmpty(this.props.placholder) ? this.props.placholder : ""}
                            value={ this.props.triptype !== "oneWay" ? this.props.value : ""}
                            onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() ;
                                 if(this.props.triptype  == "oneWay" && this.props.label == "Return" ) this.props.selectTripbtn() }}
                            onFocus={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}
                            tabindex="2"
                        /> */}
                        {/* {this.props.showCrossIcon ? (
                          <div className={this.props.styles.close_iocn}>
                            {
                              // <img src='/images/close-red.svg' tabIndex="2" style={{ width: "15px", height: "15px", right: "0" }} alt='close-icon' />
                              <svg
                                onClick={() => {
                                  this.props.roundWayReturnClick();
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                              >
                                <defs>
                                  <filter id="vesbhy9lna">
                                    <feFlood
                                      flood-color="#919191"
                                      flood-opacity="1"
                                      result="floodOut"
                                    />
                                    <feComposite
                                      operator="atop"
                                      in="floodOut"
                                      in2="SourceGraphic"
                                      result="compOut"
                                    />
                                    <feBlend in="compOut" in2="SourceGraphic" />
                                  </filter>
                                </defs>
                                <g filter="url(#vesbhy9lna)">
                                  <path
                                    fill-rule="evenodd"
                                    fill="#97144D"
                                    d="M8.1 17.999C4.036 17.999.001 13.964.001 8.1.001 4.034 4.036 0 8.1 0c5.866 0 9.899 4.034 9.899 8.1 0 5.864-4.033 9.899-9.899 9.899zM8.1.818C4.478.818.819 4.475.819 8.1c0 5.421 3.659 9.081 7.281 9.081 5.424 0 9.081-3.659 9.081-9.081 0-3.625-3.657-7.282-9.081-7.282zm1.478 8.186 2.78 2.774a.41.41 0 1 1-.581.581l-2.774-2.78-2.78 2.78a.411.411 0 1 1-.582-.581l2.774-2.774-2.774-2.78a.409.409 0 0 1 .288-.703.411.411 0 0 1 .294.121l2.78 2.774 2.774-2.774a.406.406 0 0 1 .281-.121.409.409 0 0 1 .3.703l-2.78 2.78z"
                                  />
                                </g>
                              </svg>
                            }
                          </div>
                        ) : null} */}
        
                        {   this.props.triptype === "oneWay" ? <div>
                          <p  className={this.props.styles.roundText}>
                            {"Click to add "}
                            {" a return flight"}
                          </p>
                          </div> :" "}
        
                       {   this.props.triptype !== "oneWay" ? <input
                          type="text"
                          autoComplete={"off"}
                          id="myTextField"
                          className={
                            this.props.triptype !== "oneWay"
                              ? this.props.styles.search_widget_input_new
                              : this.props.styles.roundDateDisable
                          }
                          placeholder={
                            !cf.isValueEmpty(this.props.placholder)
                              ? this.props.placholder
                              : ""
                          }
                          value={this.props.value}
                          onClick={(e) => {
                            this.onClickChangeVisibility();
                          }}
                          onFocus={(e) => {
                            /* typeof this.props.onInputFocus === "function" ? this.props.onInputFocus() : () => { } */ this.onClickChangeVisibility();
                            if (
                              this.props.triptype == "oneWay" &&
                              this.props.label == "Return"
                            )
                              this.props.selectTripbtn();
                          }}
                          tabindex="1"
                          style={{caretColor: 'transparent', cursor: 'pointer'}}
                        />  : null }
                      </>
                    )}
                </div>
                {this.props.isVisible === true ? (
                    <div ref={this.calendarWrapper} onMouseEnter={(e) => this.onMouseMoveCalendar(e, "onMouseEnter")}
                        className={this.props.type === 'double' ? this.props.styles.calendar_container + " " + this.props.styles.calendar_container_double : this.props.styles.calendar_container + " " + this.props.styles.calendar_container_single}>
                        <div onClick={(e) => { e.stopPropagation()}}  className={this.props.styles.calendar_main} >
                        {(getMonth(this.state.selectedMonthStartDate) !== getMonth(new Date()) ||
                                    (differenceInMonths(new Date(this.state.selectedMonthStartDate), new Date()) < 12) && differenceInMonths(new Date(this.state.selectedMonthStartDate), new Date()) >= 1) ?
                                    ( <div className={this.props.styles.calendar_head_left_side}  onClick={(e) => {  e.stopPropagation(); this.onClickPrevNextArrow('prev') }}>
                                    <span className={this.props.styles.calendar_head_icon} style={{ left : "20px" }}> 
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg> </span>
                                </div>
                            ) : null}
                            {differenceInMonths(new Date(this.state.selectedMonthStartDate), new Date()) < 11 ?
                                <div className={this.props.styles.calendar_head_right_side} onClick={(e) => { e.stopPropagation(); this.onClickPrevNextArrow('next') }}>
                                    <span className={this.props.styles.calendar_head_icon}> <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg> </span>
                                </div> : null}
                            {this.renderCalendar()}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}